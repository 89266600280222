<template>
  <div id="TasanteaProductList">
    <MHeader />
    <div class="banner">
      <img
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/tasantea_banner.jpg"
        alt=""
        srcset=""
      />
    </div>
    <div>
      <van-tabs v-model="activeName" color="#45B035" animated :ellipsis="false">
        <van-tab title="纯味" name="a">
          <van-tabs
            v-model="activeName_child"
            color="#45B035"
            animated
            :ellipsis="false"
          >
            <van-tab title="经典" name="a-1">
              <div
                class="product_box bg_a"
                style="margin: 9.733vw auto 37.867vw"
              >
                <div class="box_title">经典系列</div>
                <div class="dec">
                  <div class="dec_item">品鉴级名优好茶</div>
                  <div class="dec_item">匠心制作，享誉盛名</div>
                  <div class="dec_item">把功夫茶装进三角茶包</div>
                </div>
                <!-- benner -->
                <div class="swiper_box">
                  <swiper :options="swiperOption_a">
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(1)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/dahongpao.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">大红袍</div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(2)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/jinjunmei.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">金骏眉</div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(3)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/baimudan.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">白牡丹</div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(4)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/longjincha.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">龙井茶</div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div
                    class="swiper-button-prev swiper-button-prev_a"
                    slot="button-prev"
                  ></div>
                  <div
                    class="swiper-button-next swiper-button-next_a"
                    slot="button-next"
                  ></div>
                </div>
              </div>
            </van-tab>
            <van-tab title="高山" name="a-2">
              <div
                class="product_box bg_b"
                style="margin: 9.733vw auto 37.867vw"
              >
                <div class="box_title">高山系列</div>
                <div class="dec">
                  <div class="dec_item">高海拔优质好茶</div>
                  <div class="dec_item">高山孕育高含量氨基酸</div>
                  <div class="dec_item">鲜爽清甜，香气高扬</div>
                </div>
                <!-- benner -->
                <div class="swiper_box">
                  <swiper :options="swiperOption_b">
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(7)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/mingqianlvcha.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">明前绿茶</div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(8)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/chenxiangpuer.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">陈香普洱</div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(9)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/qingxinwulong.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">青心乌龙</div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(10)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/mixianghongcha.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">蜜香红茶</div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div
                    class="swiper-button-prev swiper-button-prev_b"
                    slot="button-prev"
                  ></div>
                  <div
                    class="swiper-button-next swiper-button-next_b"
                    slot="button-next"
                  ></div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </van-tab>
        <van-tab title="调味" name="c">
          <van-tabs
            v-model="activeName_child_c"
            color="#45B035"
            animated
            :ellipsis="false"
          >
            <van-tab title="果味" name="c-1">
              <div
                class="product_box bg_c"
                style="margin: 9.733vw auto 37.867vw"
              >
                <div class="box_title">果味系列</div>
                <div class="dec">
                  <div class="dec_item">上好乌龙茶邂逅</div>
                  <div class="dec_item">清甜水蜜桃</div>
                  <div class="dec_item">奶香椰肉</div>
                  <div class="dec_item">清新葡萄</div>
                  <div class="dec_item">茶香沁果香</div>
                  <div class="dec_item">每一口都是浪漫的味道</div>
                </div>
                <!-- benner -->
                <div class="swiper_box">
                  <swiper :options="swiperOption_e">
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(22)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/guoyuan_p_a.jpg"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">白桃乌龙</div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(27)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/mitaowulong.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">蜜桃乌龙</div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(23)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/guoyuan_p_b.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">椰子乌龙</div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(24)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/putaowulong.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">葡萄乌龙</div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div
                    class="swiper-button-prev swiper-button-prev_e"
                    slot="button-prev"
                  ></div>
                  <div
                    class="swiper-button-next swiper-button-next_e"
                    slot="button-next"
                  ></div>
                </div>
              </div>
            </van-tab>
            <van-tab title="花果" name="c-2">
              <div
                class="product_box bg_f"
                style="margin: 9.733vw auto 37.867vw"
              >
                <div class="box_title">花果系列</div>
                <div class="dec">
                  <div class="dec_item">经典中国花茶、果茶</div>
                  <div class="dec_item">三秋桂子、红粉蔷薇</div>
                  <div class="dec_item">养身养胃，陈皮柑香</div>
                </div>
                <!-- benner -->
                <div class="swiper_box">
                  <swiper :options="swiperOption_e">
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(25)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/guihuawulong.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">桂花乌龙</div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(28)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/meiguidianhong.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">玫瑰滇红</div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="banner_box" @click="toDetail(29)">
                        <div class="img_box">
                          <img
                            src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/chenpipuer.png"
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div class="title">
                          <div class="title_left">陈皮普洱</div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div
                    class="swiper-button-prev swiper-button-prev_e"
                    slot="button-prev"
                  ></div>
                  <div
                    class="swiper-button-next swiper-button-next_e"
                    slot="button-next"
                  ></div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </van-tab>

        <van-tab title="汉方养园" name="d">
          <div class="product_box bg_d" style="margin: 9.733vw auto 37.867vw">
            <div class="box_title">汉方养园系列</div>
            <div class="dec">
              <div class="dec_item">甄选经典好茶</div>
              <div class="dec_item">拼配汉方养生食材</div>
              <div class="dec_item">专业团队反复测评</div>
              <div class="dec_item">终得黄金配比</div>
            </div>
            <!-- benner -->
            <div class="swiper_box">
              <swiper :options="swiperOption_c">
                <swiper-slide>
                  <div class="banner_box" @click="toDetail(11)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/hanfang_p_a.png"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">冬瓜荷叶</div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="banner_box" @click="toDetail(12)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/hanfang_p_b.png"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">桂圆红枣</div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="banner_box" @click="toDetail(13)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/hanfang_p_c.png"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">红豆薏米</div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="banner_box" @click="toDetail(14)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/hanfang_p_d.png"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">金桂雪梨</div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="banner_box" @click="toDetail(15)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/hanfang_p_e.png"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">菊花决明子</div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div
                class="swiper-button-prev swiper-button-prev_c"
                slot="button-prev"
              ></div>
              <div
                class="swiper-button-next swiper-button-next_c"
                slot="button-next"
              ></div>
            </div>
          </div>
        </van-tab>
        <van-tab title="组合" name="e">
          <div class="product_box bg_e" style="margin: 9.733vw auto 37.867vw">
            <div class="box_title">组合系列</div>
            <div class="dec">
              <div class="dec_item">汇聚“他山”茗茶</div>
              <div class="dec_item">源于用户场景需求</div>
              <div class="dec_item">拼配中式茶包组合</div>
            </div>
            <!-- benner -->
            <div class="swiper_box">
              <swiper :options="swiperOption_d">
                <swiper-slide>
                  <div class="banner_box" @click="toDetail(26)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/taohuaweiyang.png"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">桃花未央</div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="banner_box" @click="toDetail(21)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_f.jpg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">半夏冷萃</div>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="banner_box" @click="toDetail(17)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_b.jpg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">21天打卡装</div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="banner_box" @click="toDetail(18)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_c.png"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">红茶大满贯</div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="banner_box" @click="toDetail(19)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_d.png"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">企业量贩装</div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="banner_box" @click="toDetail(20)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_e.jpg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">夏日果果茶</div>
                    </div>
                  </div>
                </swiper-slide>

                <swiper-slide>
                  <div class="banner_box" @click="toDetail(16)">
                    <div class="img_box">
                      <img
                        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_a.jpg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div class="title">
                      <div class="title_left">10口味组合装</div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div
                class="swiper-button-prev swiper-button-prev_d"
                slot="button-prev"
              ></div>
              <div
                class="swiper-button-next swiper-button-next_d"
                slot="button-next"
              ></div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <MobileConncatUs />
    <MFooter />
  </div>
</template>

<script>
import MHeader from "@/components/MobileHeader";
import MFooter from "@/components/MobileFooter";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import MobileConncatUs from "@/components/MobileConncatUs";
import "swiper/dist/css/swiper.css";
export default {
  name: "RangchaProductList",
  components: {
    MHeader,
    MFooter,
    swiper,
    swiperSlide,
    MobileConncatUs,
  },
  props: {},
  setup() {},
  data() {
    return {
      activeName: "a",
      swiperOption_a: {
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next_a",
          prevEl: ".swiper-button-prev_a",
          hideOnClick: true,
        },
      },
      swiperOption_b: {
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next_b",
          prevEl: ".swiper-button-prev_b",
          hideOnClick: true,
        },
      },
      swiperOption_c: {
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next_c",
          prevEl: ".swiper-button-prev_c",
          hideOnClick: true,
        },
      },
      swiperOption_d: {
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next_d",
          prevEl: ".swiper-button-prev_d",
          hideOnClick: true,
        },
      },
      swiperOption_e: {
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next_e",
          prevEl: ".swiper-button-prev_e",
          hideOnClick: true,
        },
      },
      activeName_child: "a-1",
      activeName_child_c: "c-1",
    };
  },
  methods: {
    toDetail(index) {
      this.$router.push(`/mtasantea/detail?pid=${index}`);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="less">
#TasanteaProductList {
  min-height: calc(100vh - 44px - 26.4vw);
  background: #e9e9df;
  margin-top: 18.667vw;
  /* margin-bottom: 37.333vw; */
  .banner {
    width: 100vw;
    height: 46.667vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .product_box {
    width: 93.6vw;
    height: 42.667vw;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    .box_title {
      width: 8vw;
      height: 32.8vw;
      position: absolute;
      top: -4.933vw;
      left: 3.2vw;
      background: #45B035;
      color: white;
      font-size: 14px;
      font-weight: 500;
      writing-mode: vertical-lr; //垂直方向，从左向右
      text-align: center;
      letter-spacing: 1px;
      line-height: 8vw;
    }
    .dec {
      width: 42.667vw;
      height: 32.333vw;
      position: absolute;
      top: 2.467vw;
      left: 16.133vw;
      /* font-size: 1.467vw; */
      font-size: 8px;
      color: white;
      font-weight: 400;
      .dec_item {
        height: 100%;
        writing-mode: vertical-lr; //垂直方向，从左向右
        letter-spacing: 4px;
        float: left;
        margin-right: 1.333vw;
      }
    }
    .box {
      height: 36.8vw;
      position: absolute;
      top: 32vw;
      left: 7.867vw;
      overflow: hidden;
      .box_item {
        width: 24.267vw;
        height: 36.8vw;
        float: left;
        padding: 0.533vw 0.533vw 0 0.533vw;
        box-sizing: border-box;
        background: white;
        margin-right: 2.4vw;
        .img_box {
          width: 100%;
          height: 30.933vw;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item_title {
          width: 100%;
          height: 5.333vw;
          background: #fff;
          .title_left {
            float: left;
            font-size: 10px;
            font-weight: 400;
            color: #121212;
            line-height: 5.333vw;
          }
          .title_right {
            float: right;
            width: 3.2vw;
            height: 5.333vw;
            line-height: 1.333vw;
            img {
              width: 3.2vw;
              height: 1.067vw;
            }
          }
        }
      }
    }
    .swiper_box {
      width: 80vw;
      /*  width: 88.45%; */
      height: 36.8vw;
      position: absolute;
      left: 6.8vw;
      top: 29.733vw;
      z-index: 998;
      .banner_box {
        width: 24.267vw;
        /* width: 90%; */
        height: 36.8vw;
        background: #fff;
        text-align: center;
        cursor: pointer;
        margin: 0 auto;
        padding: 4px 4px 0 4px;
        box-sizing: border-box;
        .img_box {
          width: 100%;
          height: 30.933vw;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s linear;
          }
        }

        .title {
          width: 23.2vw;
          height: 4.8vw;
          .title_left {
            float: left;
            font-size: 2.133vw;
            font-weight: 400;
            color: #121212;
            line-height: 5.8vw;
          }
          .title_right {
            float: right;
            width: 4.8vw;
            height: 4.8vw;
            line-height: 3.2vw;
            transition: all 0.5s linear;
            img {
              width: 100%;
              height: 6px;
            }
          }
        }
        &:hover {
          .img_box {
            img {
              transform: scale(1.05);
            }
          }
          .title {
            .title_right {
              transform: translateX(5px);
              opacity: 0;
            }
          }
        }
      }
      & /deep/ .swiper-button-prev {
        left: -6.4vw;
        top: 58%;
        outline: none;
        width: 4.8vw;
        height: 4.8vw;
        background-image: url("../../assets/product/icon_prev.png");
        background-size: 4.8vw 4.8vw;
      }
      & /deep/ .swiper-button-next {
        right: -6.4vw;
        top: 58%;
        outline: none;
        width: 4.8vw;
        height: 4.8vw;
        background-image: url("../../assets/product/icon_next.png");
        background-size: 4.8vw 4.8vw;
      }
    }
    .item_box {
      width: 80vw;
      /*  width: 88.45%; */
      height: 36.8vw;
      position: absolute;
      left: 6.8vw;
      top: 29.733vw;
      z-index: 998;
      .item {
        width: 24.267vw;
        /* width: 90%; */
        height: 36.8vw;
        background: #fff;
        text-align: center;
        cursor: pointer;
        margin-right: 3.2vw;
        padding: 4px 4px 0 4px;
        box-sizing: border-box;
        float: left;
        .img_box {
          width: 100%;
          height: 30.933vw;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s linear;
          }
        }

        .title {
          width: 23.2vw;
          height: 4.8vw;
          .title_left {
            float: left;
            font-size: 2.133vw;
            font-weight: 400;
            color: #121212;
            line-height: 5.8vw;
          }
          .title_right {
            float: right;
            width: 4.8vw;
            height: 4.8vw;
            line-height: 3.2vw;
            transition: all 0.5s linear;
            img {
              width: 100%;
              height: 6px;
            }
          }
        }
        &:hover {
          .img_box {
            img {
              transform: scale(1.05);
            }
          }
          .title {
            .title_right {
              transform: translateX(5px);
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .bg_a {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/series_bg/background_series_mobile_jingdian.png");
  }
  .bg_b {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/series_bg/background_gaoshan_mobile.png");
  }
  .bg_c {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/series_bg/bg_series_mobile_guowei.jpg");
  }
  .bg_d {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/t_b_d.png");
  }
  .bg_e {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/t_b_e.png");
  }
  .bg_f {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/series_bg/bg_series_mobile_huaguo.jpg");
  }
}
</style>
